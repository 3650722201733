import { Component, Input } from '@angular/core';
import { FileType } from '../../../../../../common/model/file-type.enum';
import { FileDTO } from '../../../../../../common/dto/file.dto';

@Component({
  selector: 'app-file-display',
  templateUrl: './file-display.component.html',
  styleUrls: ['./file-display.component.scss']
})
export class FileDisplayComponent {
  @Input()
  public file!: FileDTO;

  @Input()
  public width: string = '200px';

  @Input()
  public height: string = '115px';

  @Input()
  public borderRadius: number = 0;

  @Input()
  public backgroundSize: 'contain' | 'cover' = 'contain';

  public FileTypes = FileType;
}
