import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ENVIRONMENT } from '../../services/injection-tokens';
import { Environment } from '../../../../environments/environment.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {

  constructor(
    @Inject(ENVIRONMENT) private _environment: Environment,
  ) { }

  public year = new Date().getFullYear();
  public websiteUrl = this._environment.links.websiteUrl;
  public blogUrl = this._environment.links.blogUrl;
  public faqUrl = this._environment.links.faqUrl;
  public termsUrl = this._environment.links.termsUrl;
  public privacyUrl = this._environment.links.privacyUrl;

  public version = this._environment.version;
  public isProduction = this._environment.production;

  ngOnInit(): void {
  }

}
