import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { ProductDTO, ProductId } from '../../../../../common/dto/product.dto';
import { BrandId } from '../../../../../common/dto/brand.dto';
import { PCacheable, PCacheBuster } from 'ts-cacheable';
import { MINUTE_IN_MILLISECONDS } from '../../../../../common/utils/date.utils';
import { Subject } from 'rxjs';
import { TriggerMessage } from '../decorators/trigger-message.decorator';
import { MessageType } from './message-bus/message';
import { FileUploadRequestUtils } from '../utils/file-upload-request.utils';
import { DispatchStoreMessage } from '../decorators/dispatch-store-message.decorator';
import { RefreshOnboardingStateAction } from '../states/onboarding.state';
import { AuthenticationService } from './authentication.service';

export const ProductCacheBuster$ = new Subject<void>();

@Injectable()
export class ProductsService {
  constructor(
    private _http: CoolHttp,
    private _authenticationService: AuthenticationService,
  ) {}

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: ProductCacheBuster$,
  })
  public async getActiveProductsAsyncCACHED(brandId: BrandId): Promise<ProductDTO[]> {
    return await this._http.getAsync(`api/brands/${ brandId }/products`);
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: ProductCacheBuster$,
  })
  public async getAllProductsAsync(brandId: BrandId): Promise<ProductDTO[]> {
    return await this._http.getAsync(`api/brands/${ brandId }/products?inactive=1`);
  }

  public async getProductByIdAsync(brandId: BrandId, productId: ProductId): Promise<ProductDTO> {
    return await this._http.getAsync(`api/brands/${ brandId }/products/${ productId }`);
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: ProductCacheBuster$,
  })
  public async getProductByIdAsyncCACHED(brandId: BrandId, productId: ProductId): Promise<ProductDTO> {
    return await this.getProductByIdAsync(brandId, productId);
  }

  @PCacheBuster({
    cacheBusterNotifier: ProductCacheBuster$,
  })
  @TriggerMessage(MessageType.ProductRemoved, ([brandId, productId]) => { return { brandId, productId }; })
  public async removeProductAsync(brandId: BrandId, productId: ProductId) {
    return await this._http.deleteAsync(`api/brands/${ brandId }/products/${ productId }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: ProductCacheBuster$,
  })
  @TriggerMessage(([brandId, product]) => product.id ? MessageType.ProductEdited : MessageType.ProductAdded, ([brandId, product]) => product)
  @DispatchStoreMessage(new RefreshOnboardingStateAction())
  public async saveProductAsync(brandId: BrandId, product: ProductDTO): Promise<ProductDTO> {
    const formData = FileUploadRequestUtils.createRequestForm(
      'product',
      product,
      [
        {
          name: 'photo',
          resolver: (_) => _.photo,
        },
      ],
    );

    const result = await this._http.postAsync(`api/brands/${ brandId }/products`, formData);

    await this._authenticationService.refreshUserSessionAsync();

    return result;
  }

  public async setProductRankAsync(brandId: BrandId, productId: ProductId, rank: string): Promise<void> {
    await this._http.postAsync(`api/brands/${ brandId }/products/${ productId }/rank`, {
      rank: rank,
    });
  }
}
