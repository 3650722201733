import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { AccountDTO, AccountId } from '../../../../../common/dto/account.dto';
import { PCacheable } from 'ts-cacheable';
import { MINUTE_IN_MILLISECONDS } from '../../../../../common/utils/date.utils';
import { BrandMinimalDTO } from '../../../../../common/dto/brand.dto';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AccountsService {
  constructor(
    private _http: CoolHttp,
    private _authenticationService: AuthenticationService,
  ) {}

  public async getMyAccountsAsync(): Promise<AccountDTO[]> {
    return await this._http.getAsync('api/accounts/my');
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
  })
  public async getAllAvailableBrandsAsync(): Promise<BrandMinimalDTO[]> {
    return await this._http.getAsync('api/brands/available');
  }

  public async enableDropshippingAsync(accountId: AccountId) {
    await this._http.postAsync(`api/accounts/${ accountId }/features/dropshipping`);

    await this._authenticationService.refreshUserSessionAsync();
  }
}
