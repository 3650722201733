import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent implements OnInit {

  constructor() { }

  @Input()
  public size: 'normal' | 'small' = 'normal';

  @Input()
  public centered = false;

  public get diameter(): number {
    return this.size === 'normal' ? 45 : 20;
  }

  ngOnInit(): void {
  }
}
