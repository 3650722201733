import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { RouteNavigationService } from '../route-navigation.service';
import { ErrorPageType } from '../../../dashboard/error-page/error-page-type.enum';
import { IntegrationsService } from '../../services/integrations.service';

@Injectable()
export class LatestShopifyPermissionsNeededGuardLogic {
  constructor(
    private _integrationsService: IntegrationsService,
    private _routeNavigationService: RouteNavigationService,
  ) {}

  public async canActivate(): Promise<boolean> {
    const integrationState = await this._integrationsService.getShopifyIntegrationStateForPlacementAsyncCACHED();

    const hasLatestPermissions = !!integrationState?.latestScopes;

    if (!hasLatestPermissions) {
      this._routeNavigationService.gotoErrorPageAsync(ErrorPageType.LatestShopifyPermissionsNeeded);
    }

    return hasLatestPermissions;
  }
}

export const LatestShopifyPermissionsNeededGuard: CanActivateFn = (): Promise<boolean> => {
  return inject(LatestShopifyPermissionsNeededGuardLogic).canActivate();
};
