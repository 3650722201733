import { Inject, Injectable } from '@angular/core';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT } from '../injection-tokens';
import { Angulartics2, Angulartics2GoogleGlobalSiteTag, Angulartics2Segment } from 'angulartics2';
import { MessageBusService } from '../message-bus/message-bus.service';
import { TrackingEvents } from './tracking-events';
import { AppState } from '../../../app.state';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserSessionWithAppAccount } from '../../../../../../common/dto/user-session.dto';
import { filter } from 'rxjs/operators';

@Injectable()
export class TrackingService {
  private _trackingEnabled: boolean = false;

  constructor(@Inject(ENVIRONMENT) private _environment: Environment,
              private _messageBus: MessageBusService,
              private _angulartics2: Angulartics2,
              private _googleTagManagerAnalytics: Angulartics2GoogleGlobalSiteTag,
              private _angulartics2Segment: Angulartics2Segment) {
  }

  @Select(AppState.session)
  public userSession$!: Observable<UserSessionWithAppAccount>;

  public initialize() {
    if (!this._environment?.tracking?.enabled) {
      return;
    }

    this._initializeProviders();

    this.userSession$
      .pipe(
        filter(_ => !!_),
      )
      .subscribe(session => {
        this._trackingEnabled = session.analytics;

        const userId = session.user.userId.padStart(5, '0');

        this.setUserProperties({
          userId: userId,
          user_id: userId,
          accountId: session.app.accountId,
          language: session.user.language,
          mainBrandId: session.app.mainBrandId,
          isBackOfficeUser: session.backoffice,
        });
      });

    for (const trackingEvent of TrackingEvents) {
      this._messageBus.subscribe(trackingEvent.triggerMessageType)
        .subscribe(message => {
          let eventName: string;

          if (typeof (trackingEvent.eventName) === 'function') {
            eventName = trackingEvent.eventName(message.payload);
          } else {
            eventName = <string>trackingEvent.eventName;
          }

          let eventCategory: string;

          if (typeof (trackingEvent.eventCategory) === 'function') {
            eventCategory = trackingEvent.eventCategory(message.payload);
          } else {
            eventCategory = <string>trackingEvent.eventCategory;
          }

          this.trackEvent(eventName, eventCategory, trackingEvent.passPayload ? message.payload : undefined);
        });
    }
  }

  public trackEvent(name: string, category: string, properties?: any) {
    if (!this._environment?.tracking?.enabled || !this._trackingEnabled) {
      return;
    }

    this._angulartics2.eventTrack.next({
      action: name,
      properties: {
        category: category,
        ...properties,
      },
    });
  }

  public setUserProperties(properties: TrackingUserProperties) {
    if (!this._environment?.tracking?.enabled || !this._trackingEnabled) {
      return;
    }

    this._angulartics2.setUserProperties.next({
      ...properties,
      traits: properties, // HotJar integration
    });
  }

  private _initializeProviders() {
    this._angulartics2Segment.startTracking();
    this._googleTagManagerAnalytics.startTracking();
  }
}

interface TrackingUserProperties {
  userId: string;
  user_id: string;
  accountId: string | undefined;
  language: string;
  mainBrandId: string | undefined;
  isBackOfficeUser: boolean;
}
