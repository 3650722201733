import { Injectable } from '@angular/core';
import { AppStateModel } from '../../app.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OnboardingStateModel } from './onboarding.state';

@Injectable()
export class StatesService {
  constructor(
    private _store: Store,
  ) {}

  public selectAppState(): Observable<AppStateModel> {
    return this._store.select<AppStateModel>((state: any) => state.AppState);
  }

  public selectOnboardingState(): Observable<OnboardingStateModel> {
    return this._store.select<OnboardingStateModel>((state: any) => state.OnboardingState);
  }

  public selectAppStateSnapshot(): AppStateModel {
    return this._store.selectSnapshot<AppStateModel>((state: any) => state.AppState);
  }
}
