import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AppStateModel } from '../../../app.state';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
  areFeaturesEnabled,
  FeaturePermission,
  isAnyFeatureEnabled,
} from '../../../../../../common/model/feature-permissions.enum';
import { StatesService } from '../../states/states.service';

export interface FeaturePermissionsRouteGuardData {
  requiredFeaturePermissions: FeaturePermission[];

  requiredFeaturePermissionsAny?: boolean;
}

@Injectable()
export class FeaturePermissionsRouteGuardLogic {
  constructor(
    private _statesService: StatesService,
    private _router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._statesService.selectAppState()
      .pipe(
        filter((state: AppStateModel) => !!state?.isInitialized),
        take(1),
        map((applicationState: AppStateModel) => {
          const routeData: FeaturePermissionsRouteGuardData = <FeaturePermissionsRouteGuardData>route.data;

          const requiredFeaturePermissions = routeData.requiredFeaturePermissions;

          if (!requiredFeaturePermissions) {
            return false;
          }

          if (!applicationState.session?.permissions) {
            return false;
          }

          const hasPermission = (routeData.requiredFeaturePermissionsAny ? isAnyFeatureEnabled : areFeaturesEnabled)(requiredFeaturePermissions, applicationState.session.permissions);

          if (!hasPermission) {
            alert('Insufficient permission!');
          }

          return hasPermission;
        }),
      );
  }
}

export const FeaturePermissionsRouteGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  return inject(FeaturePermissionsRouteGuardLogic).canActivate(route);
};
