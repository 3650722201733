import { PlacementComponentType } from '../dto/placement.dto';

// Do not modify numbers as these are used in the database
export enum TrackingEventType {
  WidgetDisplay = -1,

  BrandImpression = 1,

  ProductClick = 2,

  BrandClick = 3,

  WidgetSeen = 4,

  PageBottomBannerClicked = 5,

  ThanksPanelButtonClicked = 6,

  PromotionClick = 7,

  DiscountCodeCopied = 8,

  BrandSocialClick = 9,
}

export enum TrackingEventSource {
  OrderStatusWidget = 1,

  Email = 2,

  CustomBrandsDisplayWidget = 3,
}

export type TrackingEventThreadId = string & { __TrackingEventThreadId: string };

export function getTrackingEventSourceFromPlacementComponentType(componentType: PlacementComponentType): TrackingEventSource {
  switch (componentType) {
    case PlacementComponentType.OrderStatusPage:
      return TrackingEventSource.OrderStatusWidget;

    case PlacementComponentType.OrderConfirmationEmail:
      return TrackingEventSource.Email;

    case PlacementComponentType.CustomBrandsDisplay:
      return TrackingEventSource.CustomBrandsDisplayWidget;

    default:
      throw new Error(`Missing conversion to event source: ${componentType}`);
  }
}

export const CONVERSION_CLICK_EVENT_TYPES = [
  TrackingEventType.BrandClick,
  TrackingEventType.BrandSocialClick,
  TrackingEventType.ProductClick,
  TrackingEventType.PromotionClick,
  TrackingEventType.DiscountCodeCopied,
];

export function isConversionClickEventType(type: TrackingEventType): boolean {
  return CONVERSION_CLICK_EVENT_TYPES.includes(type);
}