import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BrandMinimalDTO } from '../../../../../../common/dto/brand.dto';
import { RouteNavigationService } from '../../routing/route-navigation.service';

@Component({
  selector: 'app-brand-tag',
  templateUrl: './brand-tag.component.html',
  styleUrls: ['./brand-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandTagComponent {
  constructor(
    private _routeNavigationService: RouteNavigationService,
  ) {}

  @Input({ required: true })
  public brand!: BrandMinimalDTO;

  @Input()
  public showIgnored = false;

  @Input()
  public disableNavigation = false;

  public async onBrandClickAsync() {
    if (this.disableNavigation) {
      return;
    }

    await this._routeNavigationService.goToBrandDetailsAsync(this.brand.id);
  }
}
