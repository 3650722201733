import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { RouteNavigationService } from '../route-navigation.service';
import { PlacementsService } from '../../services/placements.service';
import { PlacementType } from '../../../../../../common/dto/placement.dto';
import { ErrorPageType } from '../../../dashboard/error-page/error-page-type.enum';

@Injectable()
export class ShopifyPlacementNeededRouteGuardLogic {
  constructor(
    private _placementsService: PlacementsService,
    private _routeNavigationService: RouteNavigationService,
  ) {}

  public async canActivate(): Promise<boolean> {
    const shopifyPlacement = await this._placementsService.getPlacementAsyncCACHED(PlacementType.Shopify);

    if (!shopifyPlacement.isActivated) {
      this._routeNavigationService.gotoErrorPageAsync(ErrorPageType.ShopifyPlacementNeeded);
    }

    return shopifyPlacement.isActivated;
  }
}

export const ShopifyPlacementNeededRouteGuard: CanActivateFn = (): Promise<boolean> => {
  return inject(ShopifyPlacementNeededRouteGuardLogic).canActivate();
};
