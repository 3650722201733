import { Pipe, PipeTransform } from '@angular/core';
import { formatBigNumber } from '../../../../../common/utils/number.utils';

@Pipe({
  name: 'bigNumber',
})
export class BigNumberPipe implements PipeTransform {
  public transform(value: any, ...args: any[]): any {
    return formatBigNumber(value);
  }
}
