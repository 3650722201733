import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UserSessionWithAppAccount } from '../../../common/dto/user-session.dto';

export interface AppStateModel {
  isInitialized: boolean;

  isUserLoggedIn: boolean;

  session: UserSessionWithAppAccount | undefined;

  isNavOpened: boolean;
}

export class SetIsInitializedAction {
  static readonly type = 'Set Is initialized Action';
}

export class ToggleNavOpenedAction {
  static readonly type = 'ToggleNavOpenedAction';
}

export class CloseNavOpenedAction {
  static readonly type = 'CloseNavOpenedAction';
}

export class SetSessionAction {
  static readonly type = 'Set Session Action';

  constructor(public session: UserSessionWithAppAccount | undefined) {
  }
}

@State<AppStateModel>({
  name: 'AppState',
  defaults: {
    isInitialized: false,
    isUserLoggedIn: false,
    session: undefined,
    isNavOpened: false,
  },
})
@Injectable()
export class AppState {
  @Action(SetSessionAction)
  setSession(ctx: StateContext<AppStateModel>, action: SetSessionAction) {
    return ctx.patchState({
      isUserLoggedIn: action.session !== undefined,
      session: action.session,
    });
  }

  @Action(SetIsInitializedAction)
  setInitialized(ctx: StateContext<AppStateModel>) {
    return ctx.patchState({
      isInitialized: true,
    });
  }

  @Action(ToggleNavOpenedAction)
  toggleNavOpened(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();

    return ctx.patchState({
      isNavOpened: !state.isNavOpened,
    });
  }

  @Action(CloseNavOpenedAction)
  closeNavOpenedAction(ctx: StateContext<AppStateModel>) {
    return ctx.patchState({
      isNavOpened: false,
    });
  }

  @Selector()
  static isUserLoggedIn(state: AppStateModel) {
    return state.isUserLoggedIn;
  }

  @Selector()
  static session(state: AppStateModel) {
    return state.session;
  }

  @Selector()
  static isNavOpened(state: AppStateModel) {
    return state.isNavOpened;
  }
}
