import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { OnboardingStateDTO, OnboardingStateKeys, WebsiteParseResult } from '../../../../../common/dto/onboarding.dto';
import { RouteNavigationService } from '../routing/route-navigation.service';

@Injectable()
export class OnboardingService {
  constructor(
    private _routeNavigationService: RouteNavigationService,
    private _http: CoolHttp,
  ) {

  }

  public async getOnboardingStateAsync(): Promise<OnboardingStateDTO> {
    return await this._http.getAsync('api/onboarding/state');
  }

  public async goToOnboardingStepAsync(step: OnboardingStateKeys) {
    switch (step) {
      case OnboardingStateKeys.HasBrand:
        await this._routeNavigationService.goToMyBrandAsync();
        break;
      case OnboardingStateKeys.HasProduct:
        await this._routeNavigationService.goToMyProductsAsync();
        break;
      case OnboardingStateKeys.HasAccountDetails:
        await this._routeNavigationService.goToAccountDetailsAsync();
        break;
      case OnboardingStateKeys.HasBrandActivated:
        await this._routeNavigationService.goToMyBrandAsync();
        break;
      case OnboardingStateKeys.HasActivePlacement:
        await this._routeNavigationService.goToInstallAsync();
        break;
      case OnboardingStateKeys.HasRequesterPartner:
      case OnboardingStateKeys.HasOwnerPartner:
      case OnboardingStateKeys.HasPartner:
        await this._routeNavigationService.goToBrandsAsync();
        break;
      default:
        throw new Error(`Unknown onboarding step: ${ step }`);
    }
  }

  public async parseWebsiteAsync(url: string): Promise<WebsiteParseResult> {
    return await this._http.postAsync('api/onboarding/helpers/parse-website', {
      url: url,
    });
  }
}
