import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AppStateModel } from '../../../app.state';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { RouteNavigationService } from '../route-navigation.service';
import { StatesService } from '../../states/states.service';

@Injectable()
export class LoggedInRouteGuardLogic {
  constructor(
    private _statesService: StatesService,
    private _routeNavigationService: RouteNavigationService,
  ) {}

  public canActivate(): Observable<boolean> {
    return this._statesService.selectAppState()
      .pipe(
        filter((state: AppStateModel) => !!state?.isInitialized),
        take(1),
        map((applicationState: AppStateModel) => {
          if (!applicationState.isUserLoggedIn) {
            this._routeNavigationService.goToLoginAsync(true);
          }

          return applicationState.isUserLoggedIn;
        }),
      );
  }
}

export const LoggedInRouteGuard: CanActivateFn = (): Observable<boolean> => {
  return inject(LoggedInRouteGuardLogic).canActivate();
};
