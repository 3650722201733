import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { RouteNavigationService } from '../route-navigation.service';
import { OnboardingStateModel } from '../../states/onboarding.state';
import { OnboardingStateUtils } from '../../../../../../common/dto/onboarding.dto';
import { StatesService } from '../../states/states.service';

@Injectable()
export class OnboardingWizardCompleteRouteGuardLogic {
  constructor(
    private _statesService: StatesService,
    private _routeNavigationService: RouteNavigationService,
  ) {}

  public canActivate(): Observable<boolean> {
    return this._statesService.selectOnboardingState()
      .pipe(
        filter((state: OnboardingStateModel) => state && !state.isDefault),
        take(1),
        map((onboardingState: OnboardingStateModel) => {
          const onboardingWizardComplete = OnboardingStateUtils.isOnboardingWizardComplete(onboardingState.state);

          if (!onboardingWizardComplete) {
            this._routeNavigationService.goToCurrentOnboardingWizardStepAsync(onboardingState);
          }

          return onboardingWizardComplete;
        }),
      );
  }
}

export const OnboardingWizardCompleteRouteGuard: CanActivateFn = (): Observable<boolean> => {
  return inject(OnboardingWizardCompleteRouteGuardLogic).canActivate();
};
