import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { ENVIRONMENT } from '../../services/injection-tokens';
import { Environment } from '../../../../environments/environment.interface';

@Component({
  selector: 'app-support-button',
  templateUrl: './support-button.component.html',
  styleUrls: ['./support-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportButtonComponent implements OnInit {

  constructor(
    @Inject(ENVIRONMENT) private _environment: Environment,
  ) { }

  @Input()
  public title?: string;

  @Input()
  public type: 'link' | 'button' = 'link';

  public supportEmailAddress = this._environment.links.supportEmail;

  ngOnInit(): void {
  }

}
