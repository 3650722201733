export enum LocalSettingKey {
  WelcomeMessageClosed = 'welcome-message-closed',
  DiscountsFeatureIntro = 'discounts-feature-intro'
}

export interface LocalSettingsDTO {
  [LocalSettingKey.WelcomeMessageClosed]: boolean;
  [LocalSettingKey.DiscountsFeatureIntro]: boolean;
}

export const DEFAULT_LOCAL_SETTINGS: LocalSettingsDTO = {
  [LocalSettingKey.WelcomeMessageClosed]: false,
  [LocalSettingKey.DiscountsFeatureIntro]: false,
};
