import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input()
  public height: string = '30px';

  @Input()
  public width: string = 'auto';

  @Input()
  public color: 'dark' | 'light' = 'dark';
}
