import { Injectable } from '@angular/core';
import { DiscountId, DiscountWithCodeDTO } from '../../../../../common/dto/discount.dto';
import { CoolHttp } from '@angular-cool/http';
import { FileUploadRequestUtils } from '../utils/file-upload-request.utils';
import { TriggerMessage } from '../decorators/trigger-message.decorator';
import { MessageType } from './message-bus/message';

@Injectable()
export class DiscountsService {
  constructor(
    private _http: CoolHttp,
  ) {}

  public async getAllDiscountsAsync(): Promise<DiscountWithCodeDTO[]> {
    return this._http.getAsync(`api/accounts/my/discounts`);
  }

  public async getDiscountByIdAsync(discountId: DiscountId) {
    return this._http.getAsync(`api/accounts/my/discounts/${ discountId }`);
  }

  @TriggerMessage(([discount]) => discount.id ? MessageType.DiscountEdited : MessageType.DiscountAdded, ([discount]) => discount)
  public async upsertDiscountAsync(discount: DiscountWithCodeDTO): Promise<DiscountWithCodeDTO> {
    const formData = FileUploadRequestUtils.createRequestForm(
      'discount',
      discount,
      [
        {
          name: 'photo',
          resolver: (_) => _.photo,
        },
      ],
    );

    return this._http.postAsync(`api/accounts/my/discounts`, formData);
  }

  @TriggerMessage(MessageType.DiscountArchived, ([discount]) => discount)
  public async archiveDiscountAsync(discountId: DiscountId) {
    return this._http.deleteAsync(`api/accounts/my/discounts/${ discountId }`);
  }
}
