export enum ErrorPageType {
  InsufficientPermission = 'insufficient-permission',

  BrandNeeded = 'set-up-brand',

  BrandActivationNeeded = 'brand-activation-needed',

  PlacementActivationNeeded = 'placement-activation-needed',

  ShopifyPlacementNeeded = 'shopify-placement-needed',

  LatestShopifyPermissionsNeeded = 'latest-shopify-permissions-needed',
}
