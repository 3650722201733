import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RouteLocations } from './route-locations.enum';
import { RedirectAfterService } from './redirect-after.service';
import { BrandId } from '../../../../../common/dto/brand.dto';
import { ProductId } from '../../../../../common/dto/product.dto';
import { ErrorPageType } from '../../dashboard/error-page/error-page-type.enum';
import { PlacementType } from '../../../../../common/dto/placement.dto';
import { PromotionId } from '../../../../../common/dto/promotions.dto';
import { AudienceId } from '../../../../../common/dto/audiences.dto';
import { OnboardingStateModel } from '../states/onboarding.state';
import { OnboardingStateKeys } from '../../../../../common/dto/onboarding.dto';
import { DiscountId } from '../../../../../common/dto/discount.dto';

export const NEW_ID = 'new';
export type NEW_ID_TYPE = 'new';

@Injectable()
export class RouteNavigationService {
  constructor(
    private _router: Router,
    private _redirectAfterService: RedirectAfterService,
  ) {}

  public async goToLoginAsync(redirectToCurrentUrlAfterLogin: boolean = false) {
    if (redirectToCurrentUrlAfterLogin) {
      this._redirectAfterService.saveCurrentUrlForRedirect();
    }

    await this._router.navigateByUrl(RouteLocations.Login.absoluteUrl);
  }

  public async goToRegistrationAsync(redirectToCurrentUrlAfterLogin: boolean = false) {
    if (redirectToCurrentUrlAfterLogin) {
      this._redirectAfterService.saveCurrentUrlForRedirect();
    }

    await this._router.navigateByUrl(RouteLocations.Login.getAbsoluteUrlWithQuery({
      register: '1',
    }));
  }

  public async goToCurrentOnboardingWizardStepAsync(onboardingState: OnboardingStateModel) {
    if (!onboardingState.state[OnboardingStateKeys.HasBrand]) {
      await this.goToOnboardingWizardBrandStepAsync();
    } else if (!onboardingState.state[OnboardingStateKeys.HasActivePlacement]) {
      await this.goToOnboardingWizardInstallStepAsync();
    } else if (!onboardingState.state[OnboardingStateKeys.HasProduct]) {
      await this.goToOnboardingWizardProductsStepAsync();
    } else {
      await this.goToOnboardingWizardPreviewStepAsync();
    }
  }

  public async goToOnboardingWizardBrandStepAsync() {
    await this._router.navigateByUrl(RouteLocations.OnboardingWizard.children.BrandStep.absoluteUrl);
  }

  public async goToOnboardingWizardInstallStepAsync() {
    await this._router.navigateByUrl(RouteLocations.OnboardingWizard.children.InstallStep.absoluteUrl);
  }

  public async goToOnboardingWizardProductsStepAsync() {
    await this._router.navigateByUrl(RouteLocations.OnboardingWizard.children.ProductsStep.absoluteUrl);
  }

  public async goToOnboardingWizardPreviewStepAsync() {
    await this._router.navigateByUrl(RouteLocations.OnboardingWizard.children.PreviewStep.absoluteUrl);
  }

  public async goToDashboardAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.absoluteUrl);
  }

  public async goToDashboardNotFoundAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.NotFound.absoluteUrl);
  }

  public async goToBrandsAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.Brands.absoluteUrl);
  }

  public async goToShopifyConnectAsync(shop: string) {
    await this._router.navigateByUrl(RouteLocations.ShopifyIntegration.children.ShopifyConnect.getAbsoluteUrlWithQuery({
      'shop': shop,
    }));
  }

  public async goToBrandDetailsAsync(brandId: BrandId) {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.Brands.children.BrandDetails.getAbsoluteUrlWithParams({
      brandId: brandId,
    }));
  }

  public async goToMyBrandAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.MyBrand.absoluteUrl);
  }

  public async goToPlacementDetailsPageAsync(placementType: PlacementType) {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.Placements.children.Details.getAbsoluteUrlWithParams({
      placementType: placementType,
    }));
  }

  public async goToAccountDetailsAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.AccountDetails.absoluteUrl);
  }

  public async goToMyProductsAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.MyProducts.absoluteUrl);
  }

  public async goToProductDetailsAsync(productId: 'new' | ProductId) {
    await this._router.navigateByUrl(this.getProductDetailsUrl(productId));
  }

  public getProductDetailsUrl(productId: 'new' | ProductId): string {
    return RouteLocations.Dashboard.children.MyProducts.children.MyProductDetails.getAbsoluteUrlWithParams({
      productId: productId,
    });
  }

  public async goToPromotionDetailsAsync(promotionId: 'new' | PromotionId, isNewlyAdded = false) {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.MyPromotions.children.MyPromotionDetails.getAbsoluteUrlWithParams({
      promotionId: promotionId,
    }, {
      newly: isNewlyAdded ? '1' : undefined,
    }));
  }

  public async goToMyPromotionsAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.MyPromotions.absoluteUrl);
  }

  public async goToMyDiscountsAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.MyDiscounts.absoluteUrl);
  }

  public async goToDiscountDetailsAsync(discountId: DiscountId | NEW_ID_TYPE, isNewlyAdded: boolean = false) {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.MyDiscounts.children.MyDiscountDetails.getAbsoluteUrlWithParams({
      discountId: discountId,
    }, {
      newly: isNewlyAdded ? '1' : undefined,
    }));
  }

  public async goToAudienceListAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.Audiences.children.AudienceList.absoluteUrl);
  }

  public async goToAudiencePageAsync(audienceId: 'new' | AudienceId) {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.Audiences.children.AudiencePage.getAbsoluteUrlWithParams({
      audienceId: audienceId,
    }));
  }

  public async goToAudienceExportsAsync(audienceId: AudienceId) {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.Audiences.children.AudienceExports.getAbsoluteUrlWithParams({
      audienceId: audienceId,
    }));
  }

  public async goToDropshippingFeaturePageAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.Dropshipping.children.DropshippingFeaturePage.absoluteUrl);
  }

  public async goToDropshippingCartListAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.Dropshipping.children.AffiliateCartList.absoluteUrl);
  }

  public async goToPlacementsAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.Placements.absoluteUrl);
  }

  public async goToBillingAsync() {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.Billing.absoluteUrl);
  }

  public async goToInstallAsync(type: PlacementType = PlacementType.Shopify, showSuccess = false) {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.Install.getAbsoluteUrlWithQuery({
      type: type,
      success: showSuccess ? '1' : '0',
    }));
  }

  public async goToInstallShopifyAsync(showSuccess: boolean = false) {
    await this.goToInstallAsync(PlacementType.Shopify, showSuccess);
  }

  public async gotoErrorPageAsync(type: ErrorPageType) {
    await this._router.navigateByUrl(RouteLocations.Dashboard.children.ErrorPage.getAbsoluteUrlWithQuery({
      type: type,
    }));
  }

  public async replaceQueryParamsAsync(activatedRoute: ActivatedRoute, queryParams: Params | null) {
    await this._router.navigate(
      [],
      {
        relativeTo: activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: '',
      },
    );
  }
}
