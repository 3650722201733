import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { UserSettingsDTO } from '../../../../../common/dto/user-settings.dto';

@Injectable()
export class UserSettingsService {
  constructor(private _http: CoolHttp) {}

  public async getUserSettingsAsync(): Promise<UserSettingsDTO> {
    return await this._http.getAsync('api/settings');
  }

  public async saveUserSettingsAsync(settings: UserSettingsDTO) {
    await this._http.postAsync('api/settings', settings);
  }
}
