<ng-container *ngIf="file?.type === FileTypes.Image">
  <img
    class="d-inline-block vertical-align-middle"
    crossorigin="anonymous"
    [src]="file.url"
    [style.height]="height"
    [style.width]="width"
    [style.border-radius]="borderRadius + 'px'"
    [style.object-fit]="backgroundSize"
  >
</ng-container>
