import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LoggerService } from './services/logger/logger.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { TrackingService } from './services/tracking/tracking.service';
import { AuthenticationService } from './services/authentication.service';
import { MessageBusService } from './services/message-bus/message-bus.service';
import { RouteNavigationService } from './routing/route-navigation.service';
import { ENVIRONMENT, WINDOW } from './services/injection-tokens';
import { environment } from '../../environments/environment';
import { CoolHttpModule } from '@angular-cool/http';
import { CoolStorageModule } from '@angular-cool/storage';
import { Angulartics2Module } from 'angulartics2';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from './services/translation/translation.service';
import { StartupService, startupServiceFactory } from './services/startup/startup.service';
import { GlobalErrorHandler } from './services/logger/global-error-handler';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FooterComponent } from './components/footer/footer.component';
import { DateProvider } from './services/date.provider';
import { UnauthenticatedInterceptor } from './services/http/unauthenticated.interceptor';
import { LoggedInRouteGuardLogic } from './routing/route-guards/logged-in.guard';
import { LogoComponent } from './components/logo/logo.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RedirectAfterService } from './routing/redirect-after.service';
import { FeaturePermissionEnabledDirective } from './directives/feature-permission-enabled.directive';
import { FeaturePermissionsRouteGuardLogic } from './routing/route-guards/feature-permissions.guard';
import { AsPipe } from './pipes/as.pipe';
import { BrandNeededRouteGuardLogic } from './routing/route-guards/brand-needed.guard';
import { ActivatedBrandNeededRouteGuardLogic } from './routing/route-guards/activated-brand-needed.guard';
import { ActivatedPlacementNeededRouteGuardLogic } from './routing/route-guards/activated-placement-needed.guard';
import { AccountsService } from './services/accounts.service';
import { BrandTagComponent } from './components/brand-tag/brand-tag.component';
import { FileDisplayComponent } from './components/file-display/file-display.component';
import { SupportButtonComponent } from './components/support-button/support-button.component';
import { UserSettingsService } from './services/user-settings.service';
import { LoaderContentDirective } from './directives/loader-content.directive';
import { IntegrationsService } from './services/integrations.service';
import { LoggedInRegisterRouteGuardLogic } from './routing/route-guards/logged-in-register.guard';
import { FeaturePermissionsEnabledDirective } from './directives/feature-permissions-enabled.directive';
import { FeatureDisabledDirective } from './directives/feature-disabled.directive';
import { MatButtonModule } from '@angular/material/button';
import { OnboardingService } from './services/onboarding.service';
import { OnboardingWizardCompleteRouteGuardLogic } from './routing/route-guards/onboarding-wizard-complete.guard';
import { CoolLoadingButtonModule } from '@angular-cool/loading-button';
import { BrandsService } from './services/brands.service';
import { ProductsService } from './services/products.service';
import { PlacementsService } from './services/placements.service';
import { PromotionsService } from './services/promotions.service';
import { LocalSettingsService } from './services/local-settings/local-settings.service';
import { HasAccountGuardLogic } from './routing/route-guards/has-account.guard';
import { StatesService } from './states/states.service';
import { DiscountsService } from './services/discounts.service';
import { ShopifyPlacementNeededRouteGuardLogic } from './routing/route-guards/shopify-placement-needed.guard';
import {
  LatestShopifyPermissionsNeededGuardLogic
} from './routing/route-guards/latest-shopify-permissions-needed.guard';
import { BigNumberPipe } from './pipes/big-number.pipe';
import { ToStringPipe } from './pipes/to-string.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    FontAwesomeModule,

    CoolHttpModule,
    CoolStorageModule,
    CoolLoadingButtonModule,

    MatButtonModule,
    MatProgressSpinnerModule,

    Angulartics2Module.forRoot(),

    TranslateModule.forChild(),

    NgOptimizedImage,
  ],
  declarations: [
    LoadingIndicatorComponent,
    FooterComponent,
    LogoComponent,
    FeaturePermissionEnabledDirective,
    FeatureDisabledDirective,
    FeaturePermissionsEnabledDirective,
    AsPipe,
    ToStringPipe,
    BigNumberPipe,
    BrandTagComponent,
    FileDisplayComponent,
    SupportButtonComponent,
    LoaderContentDirective,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    FontAwesomeModule,

    NgOptimizedImage,

    MatButtonModule,
    CoolHttpModule,
    CoolStorageModule,

    CoolLoadingButtonModule,

    MatProgressSpinnerModule,

    Angulartics2Module,

    TranslateModule,

    LoadingIndicatorComponent,
    FooterComponent,
    LogoComponent,
    FeaturePermissionEnabledDirective,
    FeatureDisabledDirective,
    FeaturePermissionsEnabledDirective,
    AsPipe,
    ToStringPipe,
    BigNumberPipe,
    BrandTagComponent,
    FileDisplayComponent,
    SupportButtonComponent,
    LoaderContentDirective,
  ],
})
export class AppCommonModule {
  public static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [
        LoggerService,
        TrackingService,
        RouteNavigationService,
        RedirectAfterService,
        AuthenticationService,
        UserSettingsService,
        IntegrationsService,
        OnboardingService,
        LocalSettingsService,

        BrandsService,
        ProductsService,
        PlacementsService,
        PromotionsService,
        DiscountsService,

        MessageBusService,

        DateProvider,

        TranslationService,
        StartupService,
        UnauthenticatedInterceptor,

        AccountsService,

        LoggedInRouteGuardLogic,
        LoggedInRegisterRouteGuardLogic,
        FeaturePermissionsRouteGuardLogic,
        BrandNeededRouteGuardLogic,
        ActivatedBrandNeededRouteGuardLogic,
        ActivatedPlacementNeededRouteGuardLogic,
        OnboardingWizardCompleteRouteGuardLogic,
        ShopifyPlacementNeededRouteGuardLogic,
        LatestShopifyPermissionsNeededGuardLogic,
        HasAccountGuardLogic,

        StatesService,

        ...CoolHttpModule.forRoot().providers as Provider[],
        ...CoolStorageModule.forRoot().providers as Provider[],

        { provide: ENVIRONMENT, useValue: environment },
        { provide: WINDOW, useValue: window },
        { provide: ErrorHandler, useClass: environment.logging?.enabled ? GlobalErrorHandler : ErrorHandler },
        { provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [StartupService], multi: true },
      ],
    };
  }
}
