<div class="flex-row-center cursor-pointer"
     [class.ignored]="showIgnored"
     (click)="onBrandClickAsync()"
>
  <app-file-display *ngIf="brand.logo"
                    [file]="brand.logo"
                    height="14px" width="30px">
  </app-file-display>

  <div class="ms-2 text-truncate name" style="max-width: 150px">{{ brand.name }}</div>

  <div *ngIf="showIgnored" class="ms-2 badge badge-gray">{{ 'Common.Disallowed' | translate }}</div>
</div>
