import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { CoolHttp } from '@angular-cool/http';
import { TranslationService } from '../translation/translation.service';
import { ENVIRONMENT } from '../injection-tokens';
import { Environment } from '../../../../environments/environment.interface';
import { TrackingService } from '../tracking/tracking.service';
import { LoggerService } from '../logger/logger.service';
import { MessageBusService } from '../message-bus/message-bus.service';
import { MessageType } from '../message-bus/message';
import { Store } from '@ngxs/store';
import { AuthenticationService } from '../authentication.service';
import { SetIsInitializedAction } from '../../../app.state';
import { lastValueFrom } from 'rxjs';
import { UnauthenticatedInterceptor } from '../http/unauthenticated.interceptor';
import { GlobalCacheConfig } from 'ts-cacheable';
import { MINUTE_IN_MILLISECONDS } from '../../../../../../common/utils/date.utils';

GlobalCacheConfig.maxCacheCount = 1000;
GlobalCacheConfig.maxAge = 5 * MINUTE_IN_MILLISECONDS;

export function startupServiceFactory(provider: StartupService) {
  return () => provider.setupAsync();
}

@Injectable()
export class StartupService {
  constructor(
    private _store: Store,
    private _authenticationService: AuthenticationService,
    @Inject(ENVIRONMENT) private _environment: Environment,
    private _translationService: TranslationService,
    private _trackingService: TrackingService,
    private _loggerService: LoggerService,
    private _messageBus: MessageBusService,
    private _unauthenticatedInterceptor: UnauthenticatedInterceptor,
    private _http: CoolHttp,
    private _router: Router,
  ) {

  }

  public async setupAsync() {
    this._loggerService.initialize();

    this._setupHttp();

    await this._authenticationService.initializeAsync();

    this._translationService.setup();

    this._trackingService.initialize();

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const routeRoot = this._router.routerState.snapshot.root;

          let route: ActivatedRouteSnapshot = routeRoot.firstChild!;

          while (route?.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this._messageBus.dispatch({ type: MessageType.PageView, payload: { page: route.data?.['page'] } });
      });

    await lastValueFrom(this._store.dispatch(new SetIsInitializedAction()));
  }

  private _setupHttp() {
    this._http.registerBaseUrl(this._environment.serverUrl);

    this._http.registerResponseInterceptor(this._unauthenticatedInterceptor);
  }
}
