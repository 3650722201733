export const DEFAULT_ONBOARDING_STATE: OnboardingStateDTO = {
  'hasBrand': false,
  'hasProduct': false,
  'hasAccountDetails': false,
  'isBrandActivated': false,
  'hasActivePlacement': false,
  'hasRequesterPartner': false,
  'hasOwnerPartner': false,
  'hasPartner': false,
  'hasPromotion': false,
};

export const OPT_OUT_ROLLOUT = true;

export enum OnboardingStateKeys {
  HasBrand = 'hasBrand',
  HasProduct = 'hasProduct',
  HasAccountDetails = 'hasAccountDetails',
  HasBrandActivated = 'isBrandActivated',
  HasActivePlacement = 'hasActivePlacement',
  HasRequesterPartner = 'hasRequesterPartner',
  HasOwnerPartner = 'hasOwnerPartner',
  HasPartner = 'hasPartner',
  HasPromotion = 'hasPromotion',
}

export const OnboardingStatesVisible: OnboardingStateKeys[] = <OnboardingStateKeys[]>[
  OnboardingStateKeys.HasBrand,
  OnboardingStateKeys.HasProduct,
  OnboardingStateKeys.HasActivePlacement,
  OnboardingStateKeys.HasBrandActivated,
  OPT_OUT_ROLLOUT ? undefined : OnboardingStateKeys.HasPartner,
  OnboardingStateKeys.HasAccountDetails,
].filter(_ => !!_);

export const OnboardingStateKeyDependencies = new Map([
  [
    OnboardingStateKeys.HasBrand, [],
  ],
  [
    OnboardingStateKeys.HasProduct,
    [
      OnboardingStateKeys.HasBrand,
    ],
  ],
  [
    OnboardingStateKeys.HasActivePlacement, [],
  ],
  [
    OnboardingStateKeys.HasBrandActivated,
    [
      OnboardingStateKeys.HasActivePlacement,
      OnboardingStateKeys.HasBrand,
      OnboardingStateKeys.HasProduct,
    ],
  ],
  [
    OnboardingStateKeys.HasPartner,
    [
      OnboardingStateKeys.HasBrand,
      OnboardingStateKeys.HasActivePlacement,
    ],
  ],
  [OnboardingStateKeys.HasAccountDetails, []],
]);

export interface OnboardingStateDTO {
  [OnboardingStateKeys.HasBrand]: boolean;

  [OnboardingStateKeys.HasProduct]: boolean;

  [OnboardingStateKeys.HasAccountDetails]: boolean;

  [OnboardingStateKeys.HasBrandActivated]: boolean;

  [OnboardingStateKeys.HasActivePlacement]: boolean;

  [OnboardingStateKeys.HasRequesterPartner]: boolean;

  [OnboardingStateKeys.HasOwnerPartner]: boolean;

  [OnboardingStateKeys.HasPartner]: boolean;

  [OnboardingStateKeys.HasPromotion]: boolean;
}

export interface OnboardingProgress {
  allCount: number;
  remainingCount: number;
  completionPercentage: number;

  stepsToComplete: OnboardingStateKeys[];
  furtherSteps: OnboardingStateKeys[];
  completedSteps: OnboardingStateKeys[];
}

export abstract class OnboardingStateUtils {
  public static isOnboardingFinished(state: OnboardingStateDTO): boolean {
    return state &&
      state.hasBrand &&
      state.hasProduct &&
      state.hasAccountDetails &&
      state.isBrandActivated &&
      state.hasActivePlacement &&
      ((OPT_OUT_ROLLOUT || state.hasPartner || state.hasRequesterPartner || state.hasOwnerPartner));
  }

  public static getOnboardingProgress(state: OnboardingStateDTO): OnboardingProgress {
    const allSteps = OnboardingStatesVisible;

    const completedSteps = allSteps.filter(_ => !!state[_]);

    if (allSteps.includes(OnboardingStateKeys.HasPartner) && !completedSteps.includes(OnboardingStateKeys.HasPartner) && (state.hasOwnerPartner || state.hasRequesterPartner)) {
      completedSteps.push(OnboardingStateKeys.HasPartner);
    }

    const remainingSteps = allSteps.filter(_ => !completedSteps.includes(_));

    const stepsToComplete: OnboardingStateKeys[] = [];
    const furtherSteps: OnboardingStateKeys[] = [];

    for (const step of remainingSteps) {
      const stepDependencies = OnboardingStateKeyDependencies.get(step);

      if (!stepDependencies?.length || stepDependencies.every(_ => completedSteps.includes(_))) {
        stepsToComplete.push(step);
      } else {
        furtherSteps.push(step);
      }
    }

    return {
      allCount: allSteps.length,
      remainingCount: remainingSteps.length,
      completionPercentage: Math.floor(((allSteps.length - remainingSteps.length) / allSteps.length) * 100),
      completedSteps: completedSteps,
      stepsToComplete: stepsToComplete,
      furtherSteps: furtherSteps,
    };
  }

  public static isOnboardingWizardComplete(state: OnboardingStateDTO): boolean {
    return state &&
      state.hasBrand &&
      state.hasActivePlacement &&
      state.hasProduct;
  }
}

export interface WebsiteParseResult {
  name: string | undefined;

  description: string | undefined;

  logoUrl: string | undefined;
}