import { Injectable } from '@angular/core';
import { DEFAULT_LOCAL_SETTINGS, LocalSettingsDTO } from './local-settings.dto';
import { CoolLocalStorage } from '@angular-cool/storage';

export const LOCAL_SETTINGS_STORAGE_KEY = 'ohana-local-settings';

@Injectable()
export class LocalSettingsService {
  constructor(
    private _localStorage: CoolLocalStorage,
  ) {}

  public async setLocalSettingAsync<TKey extends keyof LocalSettingsDTO>(key: TKey, value: LocalSettingsDTO[TKey]): Promise<void> {
    const settings: LocalSettingsDTO = this._localStorage.getObject<LocalSettingsDTO>(LOCAL_SETTINGS_STORAGE_KEY) ?? DEFAULT_LOCAL_SETTINGS;

    settings[key] = value;

    this._localStorage.setObject(LOCAL_SETTINGS_STORAGE_KEY, settings);
  }

  public async getLocalSettingsAsync(): Promise<LocalSettingsDTO> {
    return this._localStorage.getObject<LocalSettingsDTO>(LOCAL_SETTINGS_STORAGE_KEY) ?? DEFAULT_LOCAL_SETTINGS;
  }

  public async getLocalSettingAsync<TKey extends keyof LocalSettingsDTO>(key: TKey): Promise<LocalSettingsDTO[TKey]> {
    const settings: LocalSettingsDTO = this._localStorage.getObject<LocalSettingsDTO>(LOCAL_SETTINGS_STORAGE_KEY) ?? DEFAULT_LOCAL_SETTINGS;

    return settings[key];
  }
}
