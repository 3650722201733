<div class="pt-5 pt-lg-3 p-3 flex-column flex-lg-row flex-row-center justify-content-center">
  <div class="d-none d-lg-block mb-1">
    &copy; {{ year }} FF Ohana Limited
  </div>
  <div class="d-none d-lg-block mx-1">|</div>
  <a class="mb-1" [href]="websiteUrl" target="_blank">{{ 'Common.Links.Home' | translate }}</a>
  <div class="d-none d-lg-block mx-1">|</div>
  <a class="mb-1" [href]="blogUrl" target="_blank">{{ 'Common.Links.Blog' | translate }}</a>
  <div class="d-none d-lg-block mx-1">|</div>
  <a class="mb-1" [href]="faqUrl" target="_blank">{{ 'Common.Links.FAQ' | translate }}</a>
  <div class="d-none d-lg-block mx-1">|</div>
  <a class="mb-1" [href]="termsUrl" target="_blank">{{ 'Common.Links.TermsOfService' | translate }}</a>
  <div class="d-none d-lg-block mx-1">|</div>
  <a class="mb-1" [href]="privacyUrl" target="_blank">{{ 'Common.Links.PrivacyPolicy' | translate }}</a>
  <div class="d-none d-lg-block mx-1">|</div>
  <div class="d-lg-none mt-3">
    &copy; {{ year }} FF Ohana Limited
  </div>
  <div class="text-muted small">v{{ version }}</div>
  <ng-container *ngIf="!isProduction">
    <div class="d-none d-lg-block mx-1">|</div>
    <div class="text-danger">----- DEVELOPMENT -----</div>
  </ng-container>
</div>
