import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { FileUploadRequestUtils } from '../utils/file-upload-request.utils';
import { BrandCategoryDTO } from '../../../../../common/dto/brand-categories.dto';
import { BrandDTO, BrandId, BrandRecommendationDTO } from '../../../../../common/dto/brand.dto';
import { PCacheable, PCacheBuster } from 'ts-cacheable';
import { Subject } from 'rxjs';
import { MINUTE_IN_MILLISECONDS } from '../../../../../common/utils/date.utils';
import { MessageType } from './message-bus/message';
import { AuthenticationService } from './authentication.service';
import { DispatchStoreMessage } from '../decorators/dispatch-store-message.decorator';
import { RefreshOnboardingStateAction } from '../states/onboarding.state';
import { TriggerMessage } from '../decorators/trigger-message.decorator';
import { BrandInviteRequestDTO } from '../../../../../common/dto/brand-invite.dto';
import { AccountId } from '../../../../../common/dto/account.dto';

const brandsCacheBuster$ = new Subject<void>();

@Injectable()
export class BrandsService {
  constructor(
    private _http: CoolHttp,
    private _authenticationService: AuthenticationService,
  ) {}

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: brandsCacheBuster$,
  })
  public async getBrandsAsyncCACHED(): Promise<BrandDTO[]> {
    return await this._http.getAsync(`api/brands`);
  }

  public async getBrandsMappedAsyncCACHED(): Promise<Map<BrandId, BrandDTO>> {
    const allBrands = await this.getBrandsAsyncCACHED();

    return new Map(allBrands.map(_ => [_.id, _]));
  }

  public async getBrandsMappedByAccountIdAsyncCACHED(): Promise<Map<AccountId, BrandDTO>> {
    const allBrands = await this.getBrandsAsyncCACHED();

    return new Map(allBrands.map(_ => [_.accountId, _]));
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
  })
  public async getRecommendedBrandsAsyncCACHED(): Promise<BrandRecommendationDTO[]> {
    return await this._http.getAsync(`api/brands/recommended`);
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: brandsCacheBuster$,
  })
  public async getBrandByIdAsyncCACHED(brandId: BrandId): Promise<BrandDTO> {
    return await this._http.getAsync(`api/brands/${ brandId }`);
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: brandsCacheBuster$,
  })
  public async getMyBrandAsyncCACHED(): Promise<BrandDTO> {
    return await this._http.getAsync(`api/brands/my`);
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
  })
  public async getBrandCategoriesAsyncCACHED(): Promise<BrandCategoryDTO[]> {
    const result = await this._http.getAsync<BrandCategoryDTO[]>(`api/brand-categories`);

    result.sort((a, b) => a.priority - b.priority);

    return result;
  }

  @PCacheBuster({
    cacheBusterNotifier: brandsCacheBuster$,
  })
  @TriggerMessage(([brand]) => brand.id ? MessageType.BrandEdited : MessageType.BrandAdded, ([brand]) => brand)
  @DispatchStoreMessage(new RefreshOnboardingStateAction())
  public async saveMyBrandDetailsAsync(brand: BrandDTO): Promise<BrandDTO> {
    const formData = FileUploadRequestUtils.createRequestForm(
      'brand',
      brand,
      [
        {
          name: 'logo',
          resolver: (_) => _.logo,
        },
      ],
    );

    const result = await this._http.postAsync(`api/brands`, formData);

    await this._authenticationService.refreshUserSessionAsync();

    return result;
  }

  public async inviteBrandAsync(inviteDetails: BrandInviteRequestDTO): Promise<void> {
    await this._http.postAsync('api/brand-invites', inviteDetails);
  }

  public async getBrandsByAccountIdsAsync(accountIds: AccountId[]): Promise<BrandDTO[]> {
    const accountIdHash = new Set<AccountId>(accountIds);
    const allBrands = await this.getBrandsMappedByAccountIdAsyncCACHED();

    return Array.from(allBrands.entries()).filter(([accountId]) => accountIdHash.has(accountId)).map(([accountId, brand]) => brand);
  }

  public async getBrandByAccountIdAsyncCACHED(accountId: AccountId): Promise<BrandDTO | undefined> {
    return (await this.getBrandsMappedByAccountIdAsyncCACHED()).get(accountId);
  }

  public async searchBrandsAsync(searchFilter: string, ignoredAccountIds: AccountId[], limit: number, fullMatch: boolean = false) {
    const allBrands = await this.getBrandsAsyncCACHED();
    const ignoreAccountIdsHash = new Set<AccountId>(ignoredAccountIds);

    const normalizedSearchFilter = searchFilter.toLowerCase().trim();

    return allBrands.filter(_ => !ignoreAccountIdsHash.has(_.accountId) && (fullMatch ? _.name.trim().toLowerCase() === normalizedSearchFilter : _.name.trim().toLowerCase().includes(normalizedSearchFilter))).slice(0, limit);
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: brandsCacheBuster$,
  })
  public async checkBrandNameTakenAsync(name: string, ignoreAccountIds: AccountId[]): Promise<{ taken: boolean }> {
    if (!name.trim().toLowerCase()) {
      return { taken: false };
    }

    return await this._http.postAsync('api/brands/check-name', {
      name: name,
      ignoreAccountIds: ignoreAccountIds,
    });
  }
}
