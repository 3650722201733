import { TrackingEventType } from './tracking-event-type.enum';

export enum AnalyticsEventName {
  Cart = 'Cart',

  WidgetDisplay = 'Widget display',

  BrandImpression = 'Brand impression',

  ProductClick = 'Product click',

  PromotionClick = 'Promotion click',

  DiscountCodeCopied = 'Discount code copied',

  BrandClick = 'Brand click',

  BrandSocialClick = 'Brand social click',

  WidgetSeen = 'Widget seen',

  PageBottomBannerClicked = 'Page bottom banner clicked',

  ThanksPanelButtonClicked = 'Thanks panel button clicked',

  AccountDetailsUpdated = 'Account details updated',

  BrandEdited = 'Brand edited',

  BrandAdded = 'Brand added',

  ProductAdded = 'Product added',

  ProductEdited = 'Product edited',

  PromotionAdded = 'Promotion added',

  PromotionEdited = 'Promotion edited',

  ProductRemoved = 'Product removed',

  DiscountAdded = 'Discount added',
  DiscountEdited = 'Discount edited',
  DiscountArchived = 'Discount archived',

  PartnerRequestSent = 'Partner request sent',

  PartnerRequestRemoved = 'Partner request removed',

  PartnerRequestAccepted = 'Partner request accepted',

  PartnerRequestDeclined = 'Partner request declined',

  PartnershipStoppedByOwner = 'Partnership stopped by owner',

  PartnershipStoppedByRequester = 'Partnership stopped by requester',

  ExternalBrandInvited = 'External brand invited',

  ProductDropshippingRemovedByProductOwner = 'Dropshipping product removed by product owner',

  ProductDropshippingAdded = 'Dropshipping product added',
}

export abstract class AnalyticsEventNameUtils {
  public static fromTrackingEventType(event: TrackingEventType): AnalyticsEventName {
    switch (event) {
      case TrackingEventType.WidgetDisplay:
        return AnalyticsEventName.WidgetDisplay;

      case TrackingEventType.BrandImpression:
        return AnalyticsEventName.BrandImpression;

      case TrackingEventType.ProductClick:
        return AnalyticsEventName.ProductClick;

      case TrackingEventType.PromotionClick:
        return AnalyticsEventName.PromotionClick;

      case TrackingEventType.DiscountCodeCopied:
        return AnalyticsEventName.DiscountCodeCopied;

      case TrackingEventType.BrandClick:
        return AnalyticsEventName.BrandClick;

      case TrackingEventType.BrandSocialClick:
        return AnalyticsEventName.BrandSocialClick;

      case TrackingEventType.WidgetSeen:
        return AnalyticsEventName.WidgetSeen;

      case TrackingEventType.PageBottomBannerClicked:
        return AnalyticsEventName.PageBottomBannerClicked;

      case TrackingEventType.ThanksPanelButtonClicked:
        return AnalyticsEventName.ThanksPanelButtonClicked;

      default:
        throw new Error(`Unknown tracking event type: ${ event }`);
    }
  }
}