import { RouteLocationNode } from '../../../../../common/model/route-location-node';

export const RouteLocations = {
  Dashboard: new RouteLocationNode('dashboard', {
    DashboardHome: new RouteLocationNode(''),
    Brands: new RouteLocationNode('brands', {
      BrandMarket: new RouteLocationNode(''),
      BrandDetails: new RouteLocationNode(':brandId'),
    }),

    Dropshipping: new RouteLocationNode('dropshipping', {
      AffiliateCartList: new RouteLocationNode('affiliate'),
      SupplierCartList: new RouteLocationNode('supplier'),

      DropshippingFeaturePage: new RouteLocationNode('feature'),
    }),

    Audiences: new RouteLocationNode('audiences', {
      AudienceList: new RouteLocationNode('list'),
      AudiencePage: new RouteLocationNode(':audienceId'),
      AudienceExports: new RouteLocationNode(':audienceId/exports'),
    }),

    MyBrand: new RouteLocationNode('my-brand'),
    Reports: new RouteLocationNode('reports', {
      ReceivedReport: new RouteLocationNode('received'),
      DonatedReport: new RouteLocationNode('donated'),
    }),
    MyProducts: new RouteLocationNode('my-products', {
      MyProductList: new RouteLocationNode(''),
      MyProductDetails: new RouteLocationNode(':productId'),
    }),
    MyDiscounts: new RouteLocationNode('my-discounts', {
      MyDiscountList: new RouteLocationNode(''),
      MyDiscountDetails: new RouteLocationNode(':discountId'),
    }),
    MyPromotions: new RouteLocationNode('my-promotions', {
      MyPromotionList: new RouteLocationNode(''),
      MyPromotionDetails: new RouteLocationNode(':promotionId'),
    }),

    MyPartners: new RouteLocationNode('my-partners'),

    Install: new RouteLocationNode('install'),

    Users: new RouteLocationNode('users'),

    Placements: new RouteLocationNode('my-store', {
      List: new RouteLocationNode(''),
      Details: new RouteLocationNode(':placementType', {
        OrderStatusPage: new RouteLocationNode('order-status-page', {
          Settings: new RouteLocationNode('settings'),
        }),
        OrderStatusEmail: new RouteLocationNode('order-status-email', {
          Settings: new RouteLocationNode('settings'),
        }),
        ShopDiscountDisplay: new RouteLocationNode('shop-discount-display', {
          Settings: new RouteLocationNode('settings'),
        }),
        ShopCreditEmail: new RouteLocationNode('shop-credit-email', {
          Settings: new RouteLocationNode('settings'),
        }),
        Preview: new RouteLocationNode('preview'),
      }),
    }),

    AccountDetails: new RouteLocationNode('my-account'),

    Billing: new RouteLocationNode('billing'),

    NotFound: new RouteLocationNode('not-found'),

    ErrorPage: new RouteLocationNode('error'),
  }),

  OnboardingWizard: new RouteLocationNode('onboarding', {
    BrandStep: new RouteLocationNode('brand'),
    InstallStep: new RouteLocationNode('install'),
    ProductsStep: new RouteLocationNode('products'),
    PreviewStep: new RouteLocationNode('preview'),
  }),

  Login: new RouteLocationNode('login'),

  Settings: new RouteLocationNode('settings'),

  ShopifyIntegration: new RouteLocationNode('shopify-integration', {
    ShopifyConnect: new RouteLocationNode('connect'),
    ShopifyAfterConnect: new RouteLocationNode('after-connect'),
  }),

  Invitation: new RouteLocationNode('accept-invitation'),

  AccountCreation: new RouteLocationNode('account-create'),

  Maintenance: new RouteLocationNode('maintenance'),

  NotFound: new RouteLocationNode('not-found'),

  Redirect: new RouteLocationNode('redirect', {
    ShopifySettings: new RouteLocationNode('shopify-settings'),
    ShopifyConnect: new RouteLocationNode('shopify-connect'),
  }),
};
