export enum MessageType {
  PageView,
  ButtonClick,

  BrandAdded,
  BrandEdited,

  ProductAdded,
  ProductEdited,
  ProductRemoved,

  DiscountAdded,
  DiscountEdited,
  DiscountArchived,

  PromotionAdded,
  PromotionEdited,
  PromotionRemoved,

  PartnerRequestSent,
  PartnerRequestRemoved,
  PartnerRequestAccepted,
  PartnerRequestDeclined,
  PartnerStoppedByOwner,
  PartnerStoppedByRequester,

  AccountDetailsUpdated,
}

export interface Message<T extends MessageType> {
  type: T;

  payload?: any;
}
