import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  DEFAULT_ONBOARDING_STATE,
  OnboardingProgress,
  OnboardingStateDTO,
  OnboardingStateUtils,
} from '../../../../../common/dto/onboarding.dto';
import { OnboardingService } from '../services/onboarding.service';
import { AppState, AppStateModel } from '../../app.state';
import { delay, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export class RefreshOnboardingStateAction {
  static readonly type = 'RefreshOnboardingStateAction';
}

export class ToggleOnboardingDrawerAction {
  static readonly type = 'ToggleOnboardingDrawerAction';
}

export class OpenOnboardingDrawerAction {
  static readonly type = 'OpenOnboardingDrawerAction';
}

export interface OnboardingStateModel {
  isDefault: boolean;

  state: OnboardingStateDTO;

  progress: OnboardingProgress;

  isDrawerOpened: boolean;
}

@State<OnboardingStateModel>({
  name: 'OnboardingState',
  defaults: {
    isDefault: true,
    state: DEFAULT_ONBOARDING_STATE,
    progress: OnboardingStateUtils.getOnboardingProgress(DEFAULT_ONBOARDING_STATE),
    isDrawerOpened: false,
  },
})
@Injectable()
export class OnboardingState {
  constructor(
    private _store: Store,
    private _onboardingService: OnboardingService,
  ) {
    this.appState$
      .pipe(
        map(_ => _.isUserLoggedIn && _.session?.user.isEmailValidated && _.session?.app.accountId),
        distinctUntilChanged(),
        filter(_ => !!_),
        delay(10), // wait for action to be set up
      )
      .subscribe(() => {
        this._store.dispatch(new RefreshOnboardingStateAction());
      });
  }

  @Select(AppState)
  public appState$!: Observable<AppStateModel>;

  @Action(RefreshOnboardingStateAction)
  public async refreshOnboardingState(ctx: StateContext<OnboardingStateModel>) {
    const state = await this._onboardingService.getOnboardingStateAsync();

    return ctx.patchState({
      isDefault: false,
      state: state,
      progress: OnboardingStateUtils.getOnboardingProgress(state),
    });
  }

  @Action(OpenOnboardingDrawerAction)
  public openOnboardingDrawerAction(ctx: StateContext<OnboardingStateModel>) {
    return ctx.patchState({
      isDrawerOpened: true,
    });
  }

  @Action(ToggleOnboardingDrawerAction)
  public toggleOnboardingDrawerAction(ctx: StateContext<OnboardingStateModel>) {
    const state = ctx.getState();

    return ctx.patchState({
      isDrawerOpened: !state.isDrawerOpened,
    });
  }
}
