import { Environment } from './environment.interface';
import versionInfo from '../../../version.json';

export const environment: Environment = {
  production: true,

  version: versionInfo.version,

  serverUrl: 'https://api.joinohana.io',
  loginUrl: 'https://login.joinohana.io',
  visitorScriptsUrl: 'https://visitor.joinohana.io',
  visitorScriptIncludeUrl: 'https://visitor-script.joinohana.io',
  emailContentUrl: 'https://visitor-email-content.joinohana.io',

  logging: {
    enabled: true,

    dsn: 'https://0c545209829a42de8ca338107cc54d94@o1412514.ingest.sentry.io/6751867',
  },

  tracking: {
    enabled: true,
  },

  links: {
    termsUrl: 'https://www.joinohana.io/terms',
    privacyUrl: 'https://www.joinohana.io/privacy',
    websiteUrl: 'https://www.joinohana.io',
    blogUrl: 'https://blog.joinohana.io',
    faqUrl: 'https://www.joinohana.io/faq',
    supportEmail: 'support@joinohana.io',
  },
};
