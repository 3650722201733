const BIG_NUMBER_DIVIDERS: { multiplier: number; suffix: string; }[] = [
  { multiplier: 1000000, suffix: 'm' },
  { multiplier: 1000, suffix: 'k' },
];

export function formatBigNumber(value: number): string {
  let numberValue: number;

  if (typeof value === 'number') {
    numberValue = value;
  } else {
    numberValue = Number(value);
  }

  if (numberValue != numberValue) { // Number.isNaN
    return '' + value;
  }

  for (const divider of BIG_NUMBER_DIVIDERS) {
    if (numberValue > divider.multiplier) {
      const reducedValue = numberValue / divider.multiplier;

      let result;

      if (reducedValue < 10) {
        result = reducedValue.toFixed(1).replace('.0', '');
      } else {
        result = Math.round(reducedValue).toString();
      }

      return `${ result }${ divider.suffix }`;
    }
  }

  return numberValue.toFixed(1).replace('.0', '');
}