import { Inject, Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { WINDOW } from './injection-tokens';
import { PlacementId, PlacementType } from '../../../../../common/dto/placement.dto';
import { ShopifyIntegrationDTO } from '../../../../../common/dto/shopify-integration.dto';
import { PCacheable, PCacheBuster } from 'ts-cacheable';
import { MINUTE_IN_MILLISECONDS } from '../../../../../common/utils/date.utils';
import { Subject } from 'rxjs';
import { PlacementsService } from './placements.service';
import { IntegrationDiscountCodeDTO, IntegrationProductImportDTO } from '../../../../../common/dto/integration.dto';

const shopifyIntegrationStateCacheBuster$ = new Subject<void>();

@Injectable()
export class IntegrationsService {
  constructor(
    private _http: CoolHttp,
    private _placementsService: PlacementsService,
    @Inject(WINDOW) private _window: Window,
  ) {}

  @PCacheBuster({
    cacheBusterNotifier: shopifyIntegrationStateCacheBuster$,
  })
  public async connectToShopifyAsync(shop: string, placementId: PlacementId | undefined): Promise<boolean> {
    let tokenResponse: { token: string } | undefined = undefined;

    try {
      tokenResponse = await this._http.postAsync(`api/integrations/shopify/authentication/connect/token`, {
        shop: shop,
        placementId: placementId,
      });
    } catch {
      // This can be called without being authenticated as well
    }

    if (tokenResponse && !tokenResponse.token) {
      // no need to redirect
      return false;
    }

    this._window.open(`${ this._http.baseUrl }api/integrations/shopify/authentication/connect${ (tokenResponse?.token) ? `?token=${ encodeURIComponent(tokenResponse!.token) }` : `?shop=${ encodeURIComponent(shop) }` }`, '_top');

    return true;
  }

  @PCacheable({
    maxAge: 10 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: shopifyIntegrationStateCacheBuster$,
  })
  public async hasActiveConnectedShopifyIntegrationAsyncCACHED(): Promise<boolean> {
    const shopifyPlacement = await this._placementsService.getPlacementAsyncCACHED(PlacementType.Shopify);

    if (!shopifyPlacement) {
      return false;
    }

    const shopifyIntegrationState = await this.getShopifyIntegrationStateAsyncCACHED(shopifyPlacement.id);

    return !!shopifyIntegrationState && shopifyIntegrationState.isConnected;
  }

  @PCacheable({
    maxAge: 10 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: shopifyIntegrationStateCacheBuster$,
  })
  public async getShopifyIntegrationStateAsyncCACHED(placementId: PlacementId): Promise<ShopifyIntegrationDTO | undefined> {
    try {
      return await this._http.getAsync(`api/integrations/shopify/authentication`, {
        params: {
          placement: placementId,
        },
      });
    } catch {
      return undefined;
    }
  }

  @PCacheable({
    maxAge: 10 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: shopifyIntegrationStateCacheBuster$,
  })
  public async getShopifyIntegrationStateForPlacementAsyncCACHED(): Promise<ShopifyIntegrationDTO | undefined> {
    const placement = await this._placementsService.getPlacementAsyncCACHED(PlacementType.Shopify);

    if (!placement) {
      return undefined;
    }

    return this.getShopifyIntegrationStateAsyncCACHED(placement.id);
  }

  @PCacheBuster({
    cacheBusterNotifier: shopifyIntegrationStateCacheBuster$,
  })
  public async disconnectFromShopifyAsync(placementId: PlacementId) {
    return await this._http.deleteAsync(`api/integrations/shopify/authentication`, {
      params: {
        placement: placementId,
      },
    });
  }

  @PCacheBuster({
    cacheBusterNotifier: shopifyIntegrationStateCacheBuster$,
  })
  public async activatePreIntegrationAsync(shopifyPreIntegrationId: string) {
    await this._http.postAsync(`api/integrations/shopify/authentication/pre-integration`, {
      id: shopifyPreIntegrationId,
    });
  }

  public async getIntegrationProductsAsync(filterText: string | undefined): Promise<IntegrationProductImportDTO[]> {
    const params: { filter?: string } = {};

    if (filterText) {
      params.filter = filterText;
    }

    return await this._http.getAsync(`api/integrations/shopify/products`, {
      params: params,
    });
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
  })
  public async getDiscountCodeAsync(discountCode: string): Promise<IntegrationDiscountCodeDTO> {
    return await this._http.getAsync(`api/integrations/shopify/discount-codes`, {
      params: {
        code: discountCode,
      },
    });
  }
}
