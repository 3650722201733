import { MessageType } from '../message-bus/message';
import { AnalyticsEventName } from '../../../../../../common/model/analytics-event-names.enum';

export type TrackingEventCategory = 'Account' | 'Brand' | 'Products' | 'Discounts' | 'Partnership' | 'Misc';

export const TrackingEvents: {
  triggerMessageType: MessageType;
  eventName: string | ((payload: any) => string);
  eventCategory: TrackingEventCategory | ((payload: any) => string);
  passPayload?: boolean;
}[] = [
  {
    triggerMessageType: MessageType.ButtonClick,
    eventName: ({ what, where }: { what: string, where: string }) => `${ what } (${ where })`,
    eventCategory: 'Misc',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.AccountDetailsUpdated,
    eventName: AnalyticsEventName.AccountDetailsUpdated,
    eventCategory: 'Account',
    passPayload: false,
  },
  {
    triggerMessageType: MessageType.BrandEdited,
    eventName: AnalyticsEventName.BrandEdited,
    eventCategory: 'Brand',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.BrandAdded,
    eventName: AnalyticsEventName.BrandAdded,
    eventCategory: 'Brand',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.ProductAdded,
    eventName: AnalyticsEventName.ProductAdded,
    eventCategory: 'Products',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.ProductEdited,
    eventName: AnalyticsEventName.ProductEdited,
    eventCategory: 'Products',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.ProductRemoved,
    eventName: AnalyticsEventName.ProductRemoved,
    eventCategory: 'Products',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.DiscountAdded,
    eventName: AnalyticsEventName.DiscountAdded,
    eventCategory: 'Discounts',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.DiscountEdited,
    eventName: AnalyticsEventName.DiscountEdited,
    eventCategory: 'Discounts',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.DiscountArchived,
    eventName: AnalyticsEventName.DiscountArchived,
    eventCategory: 'Discounts',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.PartnerRequestSent,
    eventName: AnalyticsEventName.PartnerRequestSent,
    eventCategory: 'Partnership',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.PartnerRequestRemoved,
    eventName: AnalyticsEventName.PartnerRequestRemoved,
    eventCategory: 'Partnership',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.PartnerRequestAccepted,
    eventName: AnalyticsEventName.PartnerRequestAccepted,
    eventCategory: 'Partnership',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.PartnerRequestDeclined,
    eventName: AnalyticsEventName.PartnerRequestDeclined,
    eventCategory: 'Partnership',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.PartnerStoppedByOwner,
    eventName: AnalyticsEventName.PartnershipStoppedByOwner,
    eventCategory: 'Partnership',
    passPayload: true,
  },
  {
    triggerMessageType: MessageType.PartnerStoppedByRequester,
    eventName: AnalyticsEventName.PartnershipStoppedByRequester,
    eventCategory: 'Partnership',
    passPayload: true,
  },
];
