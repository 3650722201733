import { FileDTO } from '../../../../../common/dto/file.dto';
import { ObjectUtils } from '../../../../../common/utils/object.utils';

export interface FilePropertyResolver<T> {
  name: string;

  resolver: (item: T) => FileDTO | undefined;
}

export abstract class FileUploadRequestUtils {
  public static createRequestForm<T>(
    dataName: string,
    item: T,
    images: (keyof T | FilePropertyResolver<T>)[],
  ): FormData {
    const resolvers: FilePropertyResolver<T>[] = images.map((_) => {
      if ((<any>_).resolver) {
        return <FilePropertyResolver<T>>_;
      }

      return <FilePropertyResolver<T>>{
        name: _,
        resolver: (__: T) => <FileDTO | undefined>(<any>__[<keyof T>_]),
      };
    });

    const formData = new FormData();

    for (const resolver of resolvers) {
      const fileDTO = resolver.resolver(item);

      if (fileDTO?.file) {
        formData.append(resolver.name, <Blob>(<any>fileDTO.file));
      }
    }

    const copyOfItem = ObjectUtils.deepCopy(item);

    for (const resolver of resolvers) {
      const copyFileDTO = resolver.resolver(copyOfItem);

      if (copyFileDTO && copyFileDTO?.file && !copyFileDTO.id) {
        copyFileDTO.file = undefined;
        copyFileDTO.url = '';
      }
    }

    formData.append(dataName, JSON.stringify(copyOfItem));

    return formData;
  }
}
