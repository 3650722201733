import { MessageBusService } from '../services/message-bus/message-bus.service';
import { MessageType } from '../services/message-bus/message';
import { LoggerService } from '../services/logger/logger.service';
import { getFromInjector } from '../utils/injector.utils';

export function TriggerMessage(
  messageType: MessageType | ((args: any[]) => MessageType),
  payloadResolver?: (args: any[]) => any,
) {
  return function(target: any, propertyKey: string | Symbol, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function(...args: any[]) {
      const result = await originalMethod.apply(this, args);

      const logger = getFromInjector(LoggerService);

      try {
        const messageBus = getFromInjector(MessageBusService);

        let messageTypeToSend = messageType;

        if (typeof messageType === 'function') {
          messageTypeToSend = messageType(args);
        }

        messageBus.dispatch({ type: messageTypeToSend, payload: payloadResolver?.call(this, args) });
      } catch (e: any) {
        logger?.error(e);
      }

      return result;
    };
  };
}
