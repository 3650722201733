import { Store } from '@ngxs/store';
import { getFromInjector } from '../utils/injector.utils';

export function DispatchStoreMessage(message: {}) {
  return function(target: any, propertyKey: string | Symbol, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function(...args: any[]) {
      const result = await originalMethod.apply(this, args);

      const store = getFromInjector(Store);

      store.dispatch(message);

      return result;
    };
  };
}
