import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { PromotionDTO, PromotionId, PromotionMinimalDTO } from '../../../../../common/dto/promotions.dto';
import { BrandId } from '../../../../../common/dto/brand.dto';
import { PCacheable, PCacheBuster } from 'ts-cacheable';
import { MINUTE_IN_MILLISECONDS } from '../../../../../common/utils/date.utils';
import { Subject } from 'rxjs';
import { TriggerMessage } from '../decorators/trigger-message.decorator';
import { MessageType } from './message-bus/message';
import { FileUploadRequestUtils } from '../utils/file-upload-request.utils';
import { DispatchStoreMessage } from '../decorators/dispatch-store-message.decorator';
import { RefreshOnboardingStateAction } from '../states/onboarding.state';

export const PromotionCacheBuster$ = new Subject<void>();

@Injectable()
export class PromotionsService {
  constructor(
    private _http: CoolHttp,
  ) {}

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: PromotionCacheBuster$,
  })
  public async getActivePromotionsAsync(brandId: BrandId): Promise<PromotionDTO[]> {
    return await this._http.getAsync(`api/brands/${ brandId }/promotions`);
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: PromotionCacheBuster$,
  })
  public async getAllPromotionsAsync(brandId: BrandId): Promise<PromotionDTO[]> {
    return await this._http.getAsync(`api/brands/${ brandId }/promotions?inactive=1`);
  }

  public async getPromotionByIdAsync(brandId: BrandId, promotionId: PromotionId): Promise<PromotionDTO> {
    return await this._http.getAsync(`api/brands/${ brandId }/promotions/${ promotionId }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: PromotionCacheBuster$,
  })
  @TriggerMessage(MessageType.PromotionRemoved, ([brandId, promotionId]) => { return { brandId, promotionId }; })
  public async removePromotionAsync(brandId: BrandId, promotionId: PromotionId) {
    return await this._http.deleteAsync(`api/brands/${ brandId }/promotions/${ promotionId }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: PromotionCacheBuster$,
  })
  @TriggerMessage(([brandId, promotion]) => promotion.id ? MessageType.PromotionEdited : MessageType.PromotionAdded, ([brandId, promotion]) => promotion)
  @DispatchStoreMessage(new RefreshOnboardingStateAction())
  public async savePromotionAsync(brandId: BrandId, promotion: PromotionDTO): Promise<PromotionDTO> {
    const formData = FileUploadRequestUtils.createRequestForm(
      'promotion',
      promotion,
      [
        {
          name: 'image',
          resolver: (_) => _.image,
        },
      ],
    );

    return await this._http.postAsync(`api/brands/${ brandId }/promotions`, formData);
  }

  public async setPromotionRankAsync(brandId: BrandId, promotionId: PromotionId, rank: string): Promise<void> {
    await this._http.postAsync(`api/brands/${ brandId }/promotions/${ promotionId }/rank`, {
      rank: rank,
    });
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: PromotionCacheBuster$,
  })
  public async getTopActivePromotionAsyncCACHED(brandId: BrandId): Promise<PromotionMinimalDTO | undefined> {
    return await this._http.getAsync(`api/brands/${ brandId }/promotions/top-active`);
  }
}
