import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import {
  PlacementComponentDTO,
  PlacementComponentSettingsBaseDTO,
  PlacementComponentType,
  PlacementDTO,
  PlacementType,
} from '../../../../../common/dto/placement.dto';
import { PCacheable, PCacheBuster } from 'ts-cacheable';
import { MINUTE_IN_MILLISECONDS } from '../../../../../common/utils/date.utils';
import { Subject } from 'rxjs';

export const placementCacheBuster$ = new Subject<void>();
export const placementComponentsCacheBuster$ = new Subject<void>();

@Injectable()
export class PlacementsService {
  constructor(
    private _http: CoolHttp,
  ) {}

  public async getAllPlacementsAsync(): Promise<PlacementDTO[]> {
    return await this._http.getAsync('api/placements');
  }

  @PCacheable({
    maxAge: MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: placementCacheBuster$,
  })
  public async getPlacementAsyncCACHED(type: PlacementType): Promise<PlacementDTO> {
    return await this._http.getAsync(`api/placements/${ type }`);
  }

  @PCacheable({
    maxAge: MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: placementComponentsCacheBuster$,
  })
  public async getPlacementComponentAsyncCACHED<TSettings extends PlacementComponentSettingsBaseDTO>(placementType: PlacementType, componentType: PlacementComponentType): Promise<PlacementComponentDTO<TSettings>> {
    return await this.getPlacementComponentAsync(placementType, componentType);
  }

  public async getPlacementComponentAsync<TSettings extends PlacementComponentSettingsBaseDTO>(placementType: PlacementType, componentType: PlacementComponentType): Promise<PlacementComponentDTO<TSettings>> {
    return await this._http.getAsync(`api/placements/${ placementType }/components/${ componentType }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: placementComponentsCacheBuster$,
  })
  public async updatePlacementComponentSettingsAsync(placementType: PlacementType, componentType: PlacementComponentType, component: PlacementComponentDTO) {
    await this._http.postAsync(`api/placements/${ placementType }/components/${ componentType }/settings`, component);
  }
}
